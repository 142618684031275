<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <img :src="m_prokl + webp()" alt="" class="im-image" />
    </div>
    <div class="col-12 col-md-6 d-flex flex-column justify-content-between">
      <div class="flex-fill d-flex">
        <div>
          <div class="im-title">Запас прокладок</div>
          <div class="im-subtitle">
            Комплект прокладок DomSpirt из пищевого силикона
          </div>
          <div class="im-text">
            <p>
              Домашний спиртзавод DomSpirt имеет неограниченный срок
              эксплуатации, даже без проведения какого-либо ТО или ухода.
              Какие-то расходные материалы или замены уплотнений со временем не
              требуются. Но если есть какие-то сомнения, можно приобрести
              запасной комплект прокладок. Прокладки изготовлены из пищевого
              силикона, выдерживающего нагрев до 300°С и имеющего неограниченный
              срок службы.
            </p>
            <p>
              Прокладки изготовлены под стекло диоптра 3" международного
              стандарта DIN. В комплекте 2 центральные прокладки,
              устанавливающиеся на тарелку, 2 опорные прокладки под верхний и
              нижний фланец и запасной 1 ниппель в модуль контроля температуры
              или узел отбора.
            </p>
          </div>
        </div>
      </div>
      <div class="row align-items-center im-delivery-container">
        <div class="col-auto">
          <img :src="car" alt="" class="im-delivery-icon" />
        </div>
        <div class="col col-md-8 im-delivery-text">
          Доставка при заказе с аппаратом DOMSPIRT бесплатная.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const m_prokl = require("@/pages/Main/assets/modal/m_prokl.png");
const car = require("@/pages/Main/assets/car.svg");
import webp from "@/functions/webp";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "html",
  data: function () {
    return {
      webp,
      m_prokl,
      car,
    };
  },
};
</script>

<style scoped></style>
